<template>
  <!-- <CSidebar
      :minimize="minimize"
      unfoldable
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
      :style="{background: sidebar_background}"
      :dropdownMode="'closeInactive'"
      
    > -->
  <CSidebar :minimize="minimize" unfoldable :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])" :dropdownMode="'closeInactive'">
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- <h3>SEROVA</h3> -->
      <!-- <div style="width:100%;background-color: ;display: flex;justify-content: flex-start;padding-left: 15px;">
          <b-img :src="require(`../assets/icons/${logo}`)" style="width:50%; "></b-img>
        </div> -->
        <div style="width:100%;background-color: ;display: flex;justify-content: center;padding: 5px;">
          <b-img :src="logo" style="width:30%; "></b-img>
        </div>
        <!-- <h3></h3> -->
      </CSidebarBrand>
      <!-- <CSidebarForm>
  
        <div class="pl-2 pr-2 pt-2 pb-2">
          <b-form-input
            placeholder="Cari Menu..."
            v-model="searchMenu"
          ></b-form-input>
        </div>
  
      </CSidebarForm> -->
    <CRenderFunction flat :contentToRender="sidebarItemsDinkes" />

    <CSidebarFooter>
      <div class="row">
        <div class="col-6">
          <div class="dolanan">
            <div class="logout-button" @click="logout()" style="display: flex; align-items: center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 top-nav-bars"
                width="20px"
                style="margin-right: 0.5rem; color: rgb(0, 0, 0, 0.2)"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
              <div class="top-nav-bars" style="font-size: 15px; color: rgb(0, 0, 0, 0.2)">Keluar</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- <CSidebarMinimizer
              class="c-d-md-down-none"
              @click.native="$store.commit('toggle', 'sidebarMinimize')"
            /> -->
        </div>
      </div>
    </CSidebarFooter>
  </CSidebar>
</template>

<script>
import SidebarItemsDinkes from "./SidebarItemsDinkes";
import stores from "@/store/store";
// import axios from "axios";
// import { ipBackend } from "@/ipBackend.js";

export default {
  name: "TheSidebarDinkes",
  extends: SidebarItemsDinkes,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    sidebar_background() {
      return this.$store.state.color.sidebar_background;
    },
    logo() {
      return this.$store.state.logo;
    },
    // sidebar_items() {
    //   console.log("this.$store.state.sidebar_items", this.$store.state.sidebar_items);
    //   return this.$store.state.sidebar_items;
    // }
  },
  data() {
    return {
      menu: [],
      searchMenu: "",
    };
  },
  // mounted() {
  //   // this.menu = this.sidebarItemsDinkes;
  //   // this.init_sidebar_items();
  //   this.logo = this.$store.state.logo
  // },
  // watch: {
  //   // sidebarItemsDinkes: function(val) {
  //   //   this.menu = this.sidebarItemsDinkes;
  //   // },
  //   searchMenu: function(val) {
  //     if(val != '') {
  //       let menuFiltered = [];

  //       for (const parent of this.sidebarItemsDinkes[0]._children) {
  //         if(parent.name.toLowerCase().includes(val.toLowerCase())) {

  //           let menuFilteredChild = [];
  //           if(parent.items) {
  //             /** */
  //             for (const child1 of parent.items) {
  //               if(child1.name.toLowerCase().includes(val.toLowerCase())) {
  //                 menuFilteredChild.push(child1);
  //               }
  //             }

  //             // parent.items = menuFilteredChild
  //           }

  //           menuFiltered.push({ ...parent, items: menuFilteredChild });

  //         } else {
  //           let hasData = false;
  //           let menuFilteredChild = [];
  //           if(parent.items) {
  //             /** */
  //             for (const child1 of parent.items) {
  //               if(child1.name.toLowerCase().includes(val.toLowerCase())) {
  //                 hasData = true;
  //                 menuFilteredChild.push(child1);
  //               }
  //             }

  //             // parent.items = menuFilteredChild
  //           }
  //           if(hasData) {
  //             menuFiltered.push({ ...parent, items: menuFilteredChild });
  //           }
  //         }
  //       }

  //       this.menu = [
  //         {
  //           _name: "CSidebarNav",
  //           _children: menuFiltered
  //         }
  //       ];

  //     } else {

  //       this.menu = this.sidebarItemsDinkes;
  //     }
  //   }
  // },
  methods: {
    // init_sidebar_items() {
    //   axios
    //     .get(ipBackend + "menu/list/build")
    //     .then((res) => {
    //       // console.log(res.data)
    //       if(res.data.status !== 200) {
    //         this.$store.commit("set_alert", {
    //           variant: "danger",
    //           msg: res.data.message ?? "Data tidak valid.",
    //           showing: true,
    //         });
    //       }

    //       const sidebar_items_list = res.data?.data ?? [];
    //       this.$store.commit('setSidebarItemsDinkes', sidebar_items_list);
    //       this.$forceUpdate();
    //     })

    // },
    logout() {
      let vm = this;
      stores.commit("clear_data");
      stores.commit("set_alert", { variant: "success", msg: "BERHASIL LOGOUT" });
      vm.$router.push({ path: "/" });
    },
  },
};
</script>
