<template>
    <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
      <TheSidebarDinkes/>
      <TheAside/>
      <CWrapper>
        <TheHeader/>
        <div class="c-body">
          <main class="c-main pt-2 ml-0 mr-0" :style="{background: root_background}">
          <!-- <main class="c-main pt-2 ml-0 mr-0" style="background-color: #fff;"> -->
            <!-- <CContainer fluid> -->
              <TheTopBar></TheTopBar>
              <transition name="fade" mode="out-in">
                <keep-alive>
                  <router-view :key="$route.path"></router-view>
                </keep-alive>
           
              </transition>
              <TheStickyToolBar/>
            <!-- </CContainer> -->
          </main>
        </div>
        <!-- <TheFooter/> -->
      </CWrapper>
    </div>
  </template>
  
  <script>
  import TheSidebarDinkes from './TheSidebarDinkes'
  import TheHeader from './TheHeader'
  // import TheFooter from './TheFooter'
  import TheAside from './TheAside'
  import TheTopBar from './TheTopBar'
  import TheStickyToolBar from './TheStickyToolBar.vue'
  
  export default {
    name: 'TheContainerDinkes',
    components: {
      TheSidebarDinkes,
      TheHeader,
      // TheFooter,
      TheAside,
      TheTopBar,
      TheStickyToolBar
    },
    created() {
      // window.addEventListener('keydown', (e) => {
  
      //   console.log(e)
  
      // });
    },
    computed: {
      root_background () {
        return this.$store.state.color.root_background;
      }
    },
  }
  </script>
  
  <style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .top-sidebar {
    padding-bottom: 2rem;
    padding-right: 15px; 
    padding-left: 15px;
  }
  
  .top-sidebar > .child {
    width: max-content;
    background-color: #979797;
    color: white;
    border-radius: 5px 5px 5px 5px
  }
  
  .top-sidebar > .child > .child-detail {
    min-height: 2rem;
    /* background-color: aqua; */
  }
  
  .top-sidebar > .child > .child-cls {
    min-height: 2rem;
    border-radius: 0px 5px 5px 0px;
    /* background-color: aqua; */
  }
  
  .top-sidebar > .child > .child-cls:hover {
    background-color: #ff0000
    ;
  }
  </style>
  