<script>
export default {
  name: "sidebarAdmin",
  computed: {
    sidebarItemsDinkes() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.dashboard"),
              to: "/dashboard_dinkes",
              icon: "cil-speedometer"
            },
            {
              _name: "CSidebarNavDropdown",
              name: "Laporan Harian",
              route: "/laporan_dinkes",
              icon: "cil-layers",
              items: [
                {
                    name: "Kunjungan Pasien Dinkes",
                    to: "/laporan_dinkes/kunjungan_pasien",
                },
                {
                    name: "Kunjungan Pasien BPJS Dinkes",
                    to: "/laporan_dinkes/kunjungan_pasien_bpjs",
                },
                {
                    name: "Penyakit Dinkes",
                    to: "/laporan_dinkes/penyakit",
                },
                // {
                //     name: "Pemeriksaan Medis Dinkes",
                //     to: "/laporan_dinkes/pemeriksaan_medis",
                // },
                // {
                //     name: "Pelayanan Pasien Dinkes",
                //     to: "/laporan_dinkes/pelayanan_pasien",
                // },
                // {
                //     name: "Pelayanan Resep Dinkes",
                //     to: "/laporan_dinkes/pelayanan_resep",
                // },
                {
                    name: "Pemeriksaan Lab Dinkes",
                    to: "/laporan_dinkes/pemeriksaan_lab",
                },
                {
                    name: "Pengeluaran Obat Dinkes",
                    to: "/laporan_dinkes/pelayanan_obat",
                },
                {
                    name: "Tindakan Tenaga Medis Dinkes",
                    to: "/laporan_dinkes/tindakan_tenaga_medis",
                },
                // {
                //     name: "Pendapatan Tindakan Dinkes",
                //     to: "/laporan_dinkes/pendapatan_tindakan",
                // },
                {
                    name: "Rujuk Eksternal Dinkes",
                    to: "/laporan_dinkes/rujuk_eksternal",
                },
                {
                    name: "Rujuk Internal Dinkes",
                    to: "/laporan_dinkes/rujuk_internal",
                },
                // {
                //     name: "Pelayanan Ruang Dinkes",
                //     to: "/laporan_dinkes/pelayanan_ruang",
                // },
                // {
                //     name: "Kinerja Puskesmas Dinkes",
                //     to: "/laporan_dinkes/kinerja_puskesmas",
                // },
                // {
                //     name: "PKPR Dinkes",
                //     to: "/laporan_dinkes/pkpr",
                // },
                {
                    name: "Kunjungan PTM Dinkes",
                    to: "/laporan_dinkes/kunjungan_ptm",
                },
                // {
                //     name: "Pendapatan Kasir Dinkes",
                //     to: "/laporan_dinkes/pendapatan_kasir",
                // },
            ],
            },
            {
              _name: "CSidebarNavDropdown",
              name: "Laporan Bulanan",
              route: "/laporan_bulanan",
              icon: "cil-layers",
              items: [
                {
                    name: "SP3 LB1 Dinkes",
                    to: "/laporan_dinkes_bulanan/sp3lb1",
                },
                {
                    name: "UKP-4 Dinkes",
                    to: "/laporan_dinkes_bulanan/ukp4",
                },
                {
                    name: "UKP-5 Dinkes",
                    to: "/laporan_dinkes_bulanan/ukp5",
                },
                {
                    name: "Kunjungan Ibu Hamil Dinkes",
                    to: "/laporan_dinkes_bulanan/kunjungan_ibu_hamil",
                },
                {
                    name: "Kohort KIA Dinkes",
                    to: "/laporan_dinkes_bulanan/kohort_kia",
                },
                // {
                //     name: "Infeksi Menular Seksual Dinkes",
                //     to: "/laporan_dinkes_bulanan/infeksi_menular_seksual",
                // },
            ],
            },
          ]
        }
      ];
    }
  }
};
</script>
